<template>
	<div class="row align-center space-center text-center div-all">
		<div class="div-cont">
			<img src="./../assets/images/locktip.png"/>
			<h3>当前页面已锁定</h3>
			<p>输入正确密码后可成功解锁</p>
			<div class="row align-center">
				<img src="./../assets/images/lockpaw.png"/>
				<input ref="pasInput" type="password" v-model="unLockPassword" placeholder="请输入密码" @keyup.enter="sureUnlock"/>
			</div>
			<el-button class="button" type="primary" size="small" @click="sureUnlock">确 定</el-button>
		</div>
	</div>
</template>

<script>
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute, specialIpcRoute} from "@/api/main";
import {queryMarketFakeInfo} from "@/libs/http/modules/posOrder";

export default {
	name: 'mask-layer',
	data() {
		return {
			unLockPassword: '',
      originalPwd: '0000',
      proportionPwd: '8888'
		}
	},
  created() {
    this.fetchPassword()
    this.$nextTick(()=>{
      this.$refs.pasInput.focus()
    })
  },
  methods: {
    fetchPassword(){
      queryMarketFakeInfo().then(res=>{
        if(res){
          this.originalPwd = res.originalPwd
          this.proportionPwd = res.proportionPwd
        }
      }).catch(error=>{})
    },
		sureUnlock() {
			if (!this.unLockPassword){
				return this.$message({type: "error", message: `请输入解锁密码`, duration: 2000, showClose: true})
			}
			if((this.unLockPassword !== this.originalPwd) && (this.unLockPassword !== this.proportionPwd)){
				return this.$message({type: "error", message: `请输入正确的解锁密码`, duration: 2000, showClose: true})
			}
			if(this.unLockPassword === this.proportionPwd){
				this.$cache.local.set('isRevenue', 'Y')
			}else{
				this.$cache.local.set('isRevenue', 'N')
			}
			ipc.invoke(ipcApiRoute.getWCid, 'main').then(id => {
				let mainWCid = id
				if (mainWCid) {
					ipc.sendTo(mainWCid, specialIpcRoute.window2ToWindow1, 'unLockPage')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.div-all {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-image: url("./../assets/images/lockbg.png");
	background-size: cover;
	
	.div-cont {
		width: 300px;
		background: #fff;
		border-radius: 10px;
		padding: 20px;
		
		> img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: #eee;
			margin-bottom: 15px;
		}
		
		p {
			color: #999;
			margin: 10px auto 25px;
			font-size: 14px;
		}
		
		div {
			background: #F6F7FB;
			height: 36px;
			line-height: 36px;
			
			input {
				outline: none;
				background: none;
				width: 200px;
				height: 36px;
				margin-left: 10px;
				border: none;
			}
			
			img {
				width: 20px;
				margin-left: 5px;
				float: left;
			}
		}
		
		.button {
			width: 180px;
			background: #067CF2;
			margin-top: 20px;
			height: 40px;
			font-size: 16px;
		}
	}
}
</style>