import request from '@/libs/http'

export const queryBadGoodsList = params => request.post('/pos/posOrder/query-defective-goods-apply-info-list', params) //查询次品销售申请列表
export const queryBadGoodsCode = params => request.post('/pos/posOrder//query-defective-scan-code', params) //次品申请二维码
export const updateBadGoodsState = params =>
  request.post('/pos/posOrder/update-defective-goods-apply-info-state-canceled', params) //更新次品状态为已取消
export const queryShoppGuide = params => request.post('/pos/posOrder/query-shopping-guide-info', params) //查询营业员信息列表
export const queryStoreInfoList = params => request.post('/pos/posOrder/query-store-info-list', params) //查询商品信息
export const submitStoreInvoice = params => request.post('/pos/posOrder/submit-store-order-info', params) //提交商品订单信息
export const queryPaymentList = params => request.post('/pos/posOrderPayInfo/query-payment-mode-info-list', params) //查询支付方式列表
export const generatePayInfo = params => request.post('/pos/posOrderPayInfo/generate-order-payment-info', params) //生成订单支付信息
export const queryStoreInvoicesList = params => request.post('/pos/posOrder/query-pos-order-list', params) //查询销售记录
export const queryInvoicesInfo = params => request.post('/pos/posOrder/query-pos-order-detail', params) //销售记录详情
export const queryInvoiceType = params => request.post('/pos/posOrder/query-pos-order-type-info-list', params) //查询pos单据类型列表
export const queryGoodsNo = params => request.post('/pos/posOrder/query-store-spu-list', params) //货号查询
export const operateGoodsNo = params => request.post('/pos/posOrder/computed-order-detail-price', params) //操作收银货品
export const confirmPayment = params => request.post('/pos/posOrderPayInfo/confirm-payment', params) //确认支付
export const queryPayState = params => request.post('/pos/posOrderPayInfo/query-order-payment-info', params) //查询支付信息的支付状态
export const queryGoodsActinfo = params => request.post('/pos/posOrder/handle-store-activitys', params) //支付展示参与促销活动及货品信息
export const takeOutPendingList = params => request.post('/pos/posOrder/take-out-pending-order-info', params) //取出挂单信息
export const promotionActivity = params => request.post('/pos/posOrder/query-promotion-activity-detail', params) //参与的促销活动详情
export const cancelOrdingState = params => request.post('/pos/posOrder/cancel-sale-order', params) //取消订单
export const deleteOrder = params => request.post('/pos/posOrder/delete-init-order', params) //删除订单
export const queryBrokerage = params => request.post('/pos/posOrder/query-pos-support-brokerage-list', params) //查询业绩方式
export const querySingleStock = params => request.post('/pos/query-single-stock-info2', params) //查询单款库存
export const queryOtherDeptStock = params => request.post('/pos/query-other-dept-stock-by-goods-no', params) //单款库查询其他店铺或仓库库存
export const posSubmitPosAlterPrice = params =>
  request.post('/pos/approval-center/submit-pos-alter-price-review-info-impl', params) //提交改价申请
export const posQueryAlterPriceInfo = params => request.post('/pos/posOrder/query-alter-price-review-info', params) //查询改价信息详情
export const postQueryVoidInfo = params => request.post('/pos/posOrder/query-revoke-order-review-impl', params) //查询作废信息详情
export const posOrderPayInfo = params => request.post('/pos/posOrderPayInfo/valid-order-pay', params)
export const posLogistics = params => request.post('/pos/visitor-booking/query-erp-logistics-track-info-list', params)
// export const queryPublicSellList = params => request.post('/pos/summary/query-public-user-sell-by-dept', params) //查询公单业绩统计
export const queryPublicShoppingCount = params => request.post('/pos/posOrder/query-public-shopping-count', params) //查询公单店铺在编职级人员
export const queryPublicSellList = params => request.post('/pos/summary/query-order-public-rule-user', params) //查询公单业绩统计
export const queryTerminalInfo = params => request.post('/pos/info/query-terminal-info', params) //单店收银台查询
export const activityQuerySpuGoods = params => request.post('/pos/posOrder/query-spu-goods-page', params) //活动内货号搜索
export const salesAfterOperate = params => request.post('/pos/after-sales/computed-order-retreat-or-change', params) //退换货操作单据接口
export const returnSalesSubmit = params => request.post('/pos/after-sales/submit-retreat-order', params) //退货提交订单
export const returnSalesActivity = params => request.post('/pos/after-sales/get-order-activity-detail', params) //单据活动信息
export const activitySubmitGoods = params => request.post('/pos/after-sales/submit-activity-change-order', params) //促销换货提交订单
export const colorSizeSubmitGoods = params => request.post('/pos/after-sales/submit-size-or-color-order', params) //色码换货提交订单
export const queryInvoiceNewInfo = params => request.post('/pos/after-sales/query-order-after-sales-detail', params) //最新单据详情
export const submitAllRetreatOrder = params => request.post('/pos/after-sales/submit-is-all-retreat-order', params) //整单退提交订单
export const confirmedReturnOrder = params => request.post('/pos/after-sales/confirmed-return-order', params) //订单退货确认
export const queryMarketFakeInfo = params => request.post('/pos/market-fake/query-market-fake-info', params) //查询税务密码
export const queryOrderWide = params => request.post('/pos/posOrderWide/query-order-wide', params) //销售记录流水
export const querySummaryUserWide = params => request.post('/pos/summary/query-date-summary-user', params) //业绩流水
export const queryNearOrderWide = params => request.post('/pos/summary/query-near-order', params) //历史销售流水
export const getSalesOrderWeight = params => request.post('/pos/posOrder/get-sales-order-weight', params) //获取销售单货品重量
export const posSellSendVisitor = params => request.post('/pos/posOrder/sell-send-visitor', params) //销售寄件到客
export const visitorBookingOrderWeight = params => request.post('/pos/visitor-booking/order-weight', params) //客订下快递查询预估重量
export const queryPosBookingGoodDetail = params => request.post('/pos/after-sales/query-pos-booking-good-detail', params) //客订数据初始化