<template>
  <div class="component">
    <div class="img-div" :style="{ marginTop: marginTop ? marginTop + 'px' : '' }">
      <el-image class="null-img" :src="noIcon"></el-image>
    </div>
    <div class="text-desc">{{ textDesc }}</div>
  </div>
</template>

<script>
export default {
  name: "noDataState",
  props: {
    textDesc: {
      type: String,
      default: () => "暂无数据",
    },
    marginTop: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      noIcon: require('../assets/images/empty.png')
    };
  },
};
</script>
<style scoped>
.img-div {
  width: 200px;
  margin: auto;
}
.null-img {
  width: 200px;
}
.text-desc {
  text-align: center;
  font-size: 14px;
  color: #666;
  font-family: 'font-Light';
}
</style>
