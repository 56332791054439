<template>
	<div class="container">
		<nav class="fixed-nav row align-center space-between">
			<div class="nav-left row align-center">
				<div class="nav-left-logo cursor" @click="$router.go(0)">
					<img src="../assets/images/logo_name.png" alt="logo"/>
				</div>
			</div>
			<ul class="row nav-tab">
				<li><span>YY伙伴首页</span></li>
			</ul>
			<div class="nav-right row align-center">
				<div class="name-avatar row align-center cursor"
					 @mousemove="toolsVisible = true"
					 @mouseleave="toolsVisible = false">
					<div class="avatar">
						<el-image class="user-img" v-if="avatar" :src="avatar"></el-image>
						<div class="row align-center space-center user-null" v-else>
							{{ name.substring(0, 1) }}
						</div>
					</div>
					<div class="name">{{ name }}</div>
					<div class="right-tools" v-if="toolsVisible">
						<div class="tools-list">
							<div @click="quit">退 出</div>
						</div>
					</div>
				</div>
				<i class="el-icon-s-tools" @click="settingModal = true"></i>
				<div class="tools-line"></div>
				<div class="cursor nav-right-btn" @click="scanCode()">
					<p class="row align-center">
						<i class="el-icon-time" title="扫码打卡"></i>
						<span>扫码打卡</span>
					</p>
				</div>
				<div class="cursor nav-right-btn" @click="otherHelp">
					<p class="row align-center">
						<i class="el-icon-s-platform" title="远程协助"></i>
						<span>远程协助</span>
					</p>
				</div>
				<div class="cursor nav-right-btn" @click="$router.go(0)">
					<p class="row align-center">
						<i class="el-icon-refresh-left" title="刷新页面"></i>
						<span>刷新页面</span>
					</p>
				</div>
				<div class="cursor nav-right-btn" @click="minWindow">
					<p class="row align-center">
						<i class="el-icon-minus icon-color" title="最小化"></i>
						<span>最小化</span>
					</p>
				</div>
				<div class="cursor nav-right-btn" @click="closeWindow">
					<p class="row align-center">
						<i class="el-icon-close icon-color" title="关闭"></i>
						<span>关闭</span>
					</p>
				</div>
			</div>
		</nav>
		<section class="row main-cont">
			<aside class="main-left">
				<div class="aside-top row align-center space-between">
					<div class="top-title">工作台</div>
					<p class="top-more" v-if="2===1">
						<span>查看更多</span>
						<i class="el-icon-d-arrow-right"></i>
					</p>
				</div>
				<section class="work-bench-cont row flex-wrap">
          <template v-for="(item, index) in routeList">
            <div class="work-bench-item text-center cursor" v-if="!item.isHideMenu" :key="index" @click="itemHandel(item)">
              <el-image class="bench-item-img" :src="item.icon"></el-image>
              <div>{{ item.name }}</div>
            </div>
          </template>
				</section>
			</aside>
			<section class="main-center">
				<section class="main-wait">
					<div class="row space-between align-center wait-title">
						<div class="wait-title-left row align-center">
							<span>待办事项</span>
							<div class="row align-center search">
								<div class="row align-center space-between search-type">
									<div class="row align-center space-between"
										 @click.stop="typeVisible = !typeVisible">
										<p>{{ typeItem || '全部待办' }}</p>
										<i class="el-icon-arrow-down"
										   :style="{transform: typeVisible ? 'rotate(180deg)' : ''}"></i>
									</div>
									<div class="search-item" v-if="typeVisible">
										<div @click="selectType('调出单')">调出单</div>
										<div @click="selectType('客订单')">客订单</div>
									</div>
								</div>
								<input type="text" v-model="typeVal" placeholder="请输入单据编号"/>
								<el-button v-no-more-click class="search-btn">搜 索</el-button>
							</div>
						</div>
						<div class="row align-center wait-title-right">
							<span class="last-update">上次刷新时间：{{ salesTime }}</span>
							<p class="top-refresh">
								<i class="el-icon-refresh-left"></i>
								<span>刷新</span>
							</p>
							<p v-if="2===1">
								<span>查看更多</span>
								<i class="el-icon-d-arrow-right"></i>
							</p>
						</div>
					</div>
					<section>
						<noDataState v-if="!tableData.length" marginTop="60"></noDataState>
						<div class="wait-table" v-else>
							<el-table
								class="table"
								max-height="355"
								:data="tableData"
								:header-cell-style="{
									padding: '8px',
									background: '#FAFAFA',
									color: '#436481'
								}"
								:cell-style="{
							  		padding: '8px',
								}">
								<el-table-column
									align="center"
									prop="type"
									label="类型"
									width="100">
									<template slot-scope="scope">
										<span class="type">{{ scope.row.type === '1' ? '客订单' : '调出单' }}</span>
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="invoiceNo"
									label="单据号">
									<template slot-scope="scope">
										<span class="invoice">{{ scope.row.invoiceNo }}</span>
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="createTime"
									label="单据日期">
								</el-table-column>
								<el-table-column
									align="center"
									prop="joinUnitName"
									label="关联店仓">
								</el-table-column>
								<el-table-column
									align="center"
									prop="overdueDays"
									label="逾期天数">
								</el-table-column>
							</el-table>
						</div>
					</section>
				</section>
				<article class="main-msg">
					<div class="row space-between align-center msg-title">
						<div class="msg-title-left row align-center">
							<span>企业窗口</span>
						</div>
						<div class="row align-center msg-title-right">
							<span class="last-update">上次刷新时间：{{ messageTime }}</span>
							<p class="top-refresh" @click="fetchNewsListQuery">
								<i class="el-icon-refresh-left"></i>
								<span>刷新</span>
							</p>
							<p v-if="2===1">
								<span>查看更多</span>
								<i class="el-icon-d-arrow-right"></i>
							</p>
						</div>
					</div>
					<div class="msg-list">
						<div v-for="item in newsList" :key="item.id" class="msg-card row" @click="showNewsDetail(item)">
							<div class="msg-card-left" v-if="item.thumbImages">
								<el-image class="msg-img" :src="item.thumbImages[0]"></el-image>
							</div>
							<div
								:class="['msg-card-right', 'column', 'space-between', item.thumbImages ? 'has-left' : '']">
								<div class="msg-cont row align-center">
									<p>{{ item.title }}</p>
								</div>
								<div class="msg-info">{{ item.thumbContent }}</div>
								<div class="row align-center" style="font-size: 14px;">
									<span style="color: #FF9000;">{{ item.createUserDept }}</span>
									<span style="margin: 0 10px;color:#999;">·</span>
									<span style="color: #999;">{{ item.createTime }}</span>
									<span style="margin: 0 10px;color:#999;">·</span>
									<span style="color: #999;">{{ item.browseCount }}次阅读</span>
								</div>
							</div>
						</div>
					</div>
				</article>
			</section>
			<aside class="main-right" v-if="1===2">
				<div class="sales-card">
					<div class="aside-top row align-center space-between">
						<div class="top-title">业绩看板</div>
						<div class="row align-center space-end">
							<span class="last-update">上次刷新时间：{{ salesTime }}</span>
							<p class="top-refresh" @click="fetchSalesDay();fetchSalesMonth();">
								<i class="el-icon-refresh-left"></i>
								<span>刷新</span>
							</p>
						</div>
					</div>
					<div class="card" style="margin-bottom: 10px;">
						<p>今日业绩差额(元)</p>
						<div class="row align-center sales-val">
							¥{{
								todaySales && (todaySales.challengeTarget - todaySales.totalAmount) >= 0 ? (todaySales.challengeTarget - todaySales.totalAmount).toFixed(2) : '0.00'
							}}
							<span style="background: #F8475F;">今日</span>
						</div>
						<div class="row align-center space-between text-center sales-info">
							<div>
								<p>¥{{ todaySales ? todaySales.challengeTarget : '0.00' }}</p>
								<span>今日挑战目标</span>
							</div>
							<div>
								<p>¥{{ todaySales ? todaySales.totalAmount : '0.00' }}</p>
								<span>实际完成</span>
							</div>
						</div>
					</div>
					<div class="card">
						<p>本月业绩差额(元)</p>
						<div class="row align-center sales-val">
							¥{{
								monthSales && (monthSales.challengeTarget - monthSales.totalAmount) >= 0 ? (monthSales.challengeTarget - monthSales.totalAmount).toFixed(2) : '0.00'
							}}
							<span style="background: #067CF2;">本月</span>
						</div>
						<div class="row align-center space-between text-center sales-info">
							<div>
								<p>¥{{ monthSales ? monthSales.challengeTarget : '0.00' }}</p>
								<span>本月挑战目标</span>
							</div>
							<div>
								<p>¥{{ monthSales ? monthSales.totalAmount : '0.00' }}</p>
								<span>实际完成</span>
							</div>
						</div>
					</div>
				</div>
				<div class="guider-card">
					<div class="aside-top row align-center space-between">
						<div class="top-title">营业员</div>
						<div class="row align-center space-end">
							<span class="last-update">上次刷新时间：{{ guideTime }}</span>
							<p class="top-refresh" @click="fetchDeptUser">
								<i class="el-icon-refresh-left"></i>
								<span>刷新</span>
							</p>
						</div>
					</div>
					<div class="guide-list">
						<div class="row align-center text-center guide-title">
							<div style="width:80px;text-align: center;">序号</div>
							<div style="width:160px;">营业员名称</div>
							<div style="width: 140px;">营业员业绩(元)</div>
						</div>
						<div class="guide-user">
							<div class="row align-center text-center" v-for="(item, index) in guideList" :key="index">
								<div style="width:80px;color: #FF9000;text-align: center;font-size:18px;">
									{{ index + 1 }}
								</div>
								<div class="row align-center"
									 style="width:160px;color: #6B7A8F;padding-left:30px;box-sizing:border-box;font-size:18px;">
									<el-image v-if="1==2"
											  src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
											  style="width: 36px;height: 36px;border-radius: 50%;margin-right:5px;"></el-image>
									<div v-else class="row align-center space-center"
										 style="width: 36px;height: 36px;border-radius: 50%;color: #fff;background: #067CF2;font-size: 18px;margin-right:5px;">
										{{ item.userName.substr(0, 1) }}
									</div>
									<span style="margin-left:4px;font-family: 'font-Light';">{{ item.userName }}</span>
								</div>
								<div style="width:140px;color: #333;font-size:22px;">¥{{ item.totalAmount }}</div>
							</div>
						</div>
					</div>
				</div>
			</aside>
		</section>
		<MaskLayer v-if="lockScroll"></MaskLayer>
		<setting v-if="settingModal" @close="settingModal = false"></setting>
		<CheckAudioDialog v-if="studyDialog" @close="studyDialog = false"></CheckAudioDialog>
		<UpdateModule v-if="isUpdate" :versionData="versionData" @close="isUpdate = false"></UpdateModule>
		<HomeNewsDetail v-if="newsDetail" @close="newsDetail = false" :modalParams="newsItem" :src="srcLink"></HomeNewsDetail>
		<DialogModule
            width="500px"
            :title="titleQrCode"
            top="10"
            :dialogVisible="true"
            @close="closeScanCode"
			v-if="showScanCode"
        >
            <span slot="close" class="el-icon-close" @click="closeScanCode"></span>
            <div slot="content" class="print-view">
	     		<div style="width:100%;text-align: center;margin-bottom: 10px;margin-top:20px;font-size: 20px;">当前日期：{{ dateNew }} {{ dataDay }}</div>
				<div style="width:100%;text-align: center;font-size: 20px;margin-bottom: 20px;">当前时间：{{ timeNew }}</div>
				<div style="width:100%;text-align: center;margin-bottom: 20px;">
					<div style="margin-left: 150px;display: flex;">
						<div v-show="!loading" class="qrcode2" ref="qrcodeImage" style="width:200px;height:200px;"></div>
						<div v-show="loading" style="width:200px;height:200px;background-color: #808080;text-align: center;">
							<div style="color:#fff;font-size: 16px;margin-top:90px;">
								加载中...
							</div>
						</div>
					</div>
					 <div style="width:100%;text-align: center;height:30px;">扫此码打卡，二维码间隔{{countDown}}s刷新</div>
					 <!-- <el-button type="primary" :loading="loading" style="width:300px;margin-bottom: 20px;" @click="clickScanCode()">刷 新</el-button> -->
				</div>
            </div>
            
        </DialogModule>
		
	</div>
</template>

<script>
import DialogModule from '@/components/dialogModule.vue'
import moment from 'moment'
import { sendWebsocket, closeWebsocket } from '@/utils/websocket.js'
import {ipcApiRoute, specialIpcRoute} from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
import eventBus from '@/bus/index.js'
import setting from "@/components/setting.vue"
import UpdateModule from "@/components/UpdateModule.vue"
import NoDataState from "@/components/noDataState.vue"
import MaskLayer from "@/components/Masklayer"
import HomeNewsDetail from '@/components/HomeNewsDetail.vue'
import CheckAudioDialog from '@/components/CheckAudioDialog.vue'
import { salesQueryGrade, queryDeptUserSales, NewsListQuery, updateTrtcState, appUpdate, getHrAttendanceQrCodeList } from '@/libs/http/api.js'
import QRCode from 'qrcodejs2'
export default {
	name: 'Home',
	components: {
		setting,
		MaskLayer,
		NoDataState,
		DialogModule,
		UpdateModule,
		HomeNewsDetail,
		CheckAudioDialog
	},
	data() {
		return {
			loading:false,
			dateNew:moment().format('YYYY-MM-DD'),
			dataDay: '',
			timeNew:'',
			getTime:null,
			titleQrCode:'扫码打卡',
			showScanCode:false,
			qrCodeImage:'',
			qrCodeDuration:5,
			countDown:0,
			interval:null,
			timmer:null,
			lockScroll: false,
			name: this.$cache.local.get('name'),
			avatar: this.$cache.local.get('avatar'),
			routeList: [],
			salesTime: moment().format('HH:mm:ss'),
			guideTime: moment().format('HH:mm:ss'),
			taskTIme: moment().format('HH:mm:ss'),
			messageTime: moment().format('HH:mm:ss'),
			toolsVisible: false,
			typeVal: '',
			todaySales: null,
			monthSales: null,
			guideList: [],
			newsList: [],
			newsDetail: false,
			newsItem: null,
			srcLink: '',
			studyDialog: false,
			versionData: null,
			isUpdate: false,
			typeVisible: false,
			typeItem: null,
			tableData: [],
			settingModal: false,
			curVersion: '',
			time:'',
			showCode:'',
			codeList:[],
			num:0,
		}
	},
  created() {
    this.routeList = JSON.parse(this.$cache.local.get('routeList')) || []
  },
  mounted() {
		document.title = 'YY伙伴-首页';
    this.setMenuVisible();
		this.fetchSalesDay();
		this.fetchSalesMonth();
		this.fetchDeptUser();
		this.fetchNewsListQuery();
		document.body.addEventListener("click", () => {
			this.typeVisible = false
		})
		ipc.on(specialIpcRoute.window2ToWindow1, (event, arg) => {
      if(arg === 'lockPage'){
        this.lockScroll = true
      }else{
        this.setMenuVisible()
        this.lockScroll = false
      }
		})
		
		ipc.invoke(ipcApiRoute.getPrinters, {}).then(r => {
			this.$cache.local.set('printList', JSON.stringify(r))
		})
		
		ipc.invoke(ipcApiRoute.checkMicrophone, {}).then(r => {
			if(r){
				this.studyDialog = true
			}
		})
		
		ipc.invoke(ipcApiRoute.getVersion).then(r => {
			this.curVersion = r
			this.checkUpdate()
		})
		
		ipc.invoke(ipcApiRoute.trtcGetMicDevice, {}).then(r => {
			this.$store.commit('initDeviceMicrophoneList', r);
		})
		this.initWebsocket();
		eventBus.$on('globalInitWebSocket', msg => {
			this.initWebsocket();
		})
	},
	beforeDestroy() {
		this.exitHome()
	},
	methods: {
    setMenuVisible(){
      let isRevenue = this.$cache.local.get('isRevenue')
      if(isRevenue === 'Y'){
        let hideMenu = ['/salesRecord', '/performanceCalendar']
        this.routeList.forEach(item=>{
          if(hideMenu.includes(item.route)){
            this.$set(item, 'isHideMenu', true)
          }
        })
      }else{
        this.routeList.map(item=>{
          if(item.isHideMenu){
            delete item.isHideMenu
          }
        })
      }
    },
		// 生成二维码
		showQRCode() {
		    //先清除已有二维码
		    this.$refs.qrcodeImage.innerHTML = ''
			var code = new QRCode(this.$refs.qrcodeImage, {
				text: this.qrCodeImage,
				width: 200,
				height: 200,
				colorDark: '#000000',
				colorLight: '#ffffff',
			});
			code._el.title=''
		},
		scanCode(){
			// 每秒钟更新一次时间
            this.getTime = setInterval(()=>{
				var now = new Date();
                var hours = now.getHours();
                var minutes = now.getMinutes();
                var seconds = now.getSeconds();
                var timeString = hours.toString().padStart(2, '0') + ":" + 
                                 minutes.toString().padStart(2, '0') + ":" + 
                                 seconds.toString().padStart(2, '0');
                this.timeNew = timeString;
				if(this.timeNew=='00:00:00'){
					this.dateNew = moment().format('YYYY-MM-DD')
					let week = moment().weekday()
                    if(week==1){this.dataDay = '周一'}
			        if(week==2){this.dataDay = '周二'}
			        if(week==3){this.dataDay = '周三'}
			        if(week==4){this.dataDay = '周四'}
			        if(week==5){this.dataDay = '周五'}
			        if(week==6){this.dataDay = '周六'}
			        if(week==7){this.dataDay = '周日'}
				}
			}, 1000)
			this.showScanCode = true
			let week = moment().weekday()
            if(week==1){this.dataDay = '周一'}
			if(week==2){this.dataDay = '周二'}
			if(week==3){this.dataDay = '周三'}
			if(week==4){this.dataDay = '周四'}
			if(week==5){this.dataDay = '周五'}
			if(week==6){this.dataDay = '周六'}
			if(week==7){this.dataDay = '周日'}
			this.getQrCode()
		},
		closeScanCode(){
			clearTimeout(this.interval)
			clearInterval(this.timmer)
			clearInterval(this.getTime)
			clearTimeout(this.showCode)
			this.interval = null
			this.timmer = null
			this.getTime = null
			this.showCode = null
			this.showScanCode = false
		},
		getQrCode(){
			if(this.loading)return 
			this.loading = true
			this.num = 0
			clearTimeout(this.showCode)
			clearInterval(this.timmer)
			var startTime = Date.now(); //todo
			getHrAttendanceQrCodeList({deptId:this.$cache.local.get('deptId'),}).then(res => {
				
				this.codeList = res.list
				var endTime = Date.now(); //todo
				//请求时长，单位为秒
                var requestDuration = (endTime - startTime) / 1000; 
                //刷新间隔时间的一半
                var halfInterval = res.qrCodeDuration / 2; 
                //已经过期的个数
                var expiredCount = Math.floor(requestDuration / res.qrCodeDuration);
                //计算请求时长与刷新间隔时间的余数，表示当前请求周期内剩余的时间,单位为秒
                var remainingTime = requestDuration % res.qrCodeDuration;
                //如果剩余时间大于等于刷新间隔时间的一半，取下一个二维码
                var validIndex = remainingTime >= halfInterval ? expiredCount + 1 : expiredCount;
                //删除过期的二维码数据
                this.codeList = this.codeList.slice(validIndex);
                //判断是否还有有效数据
                if (this.codeList.length > 0) {
                    //正常往下走取数 //todo
					this.qrCodeDuration = res.qrCodeDuration + '000'
					// this.countDown = res.qrCodeDuration
					this.qrCodeImage = this.codeList[this.num].code
					console.log(this.qrCodeImage)
					this.showQRCode()
					this.showCodeImage()
                } else {
                    //弹出提示让用户主动关闭弹窗重新打开 //todo
					this.$alert('加载时间过长，请关闭扫码打卡弹窗重新打开', {
                      confirmButtonText: '确定',
					  title:'提示',
                    });
                }
				this.loading = false
			}).catch(err=>{
				this.loading = false
				this.$message({type: "error", message: err, duration: 2000, showClose: true});
			})
		},

		showCodeImage(){
			this.num = this.num + 1
			if(this.num >= this.codeList.length - 1){
				this.getQrCode()
				
			}else{
			    this.countDown = this.codeList[this.num].qrCodeDuration
			    clearInterval(this.timmer)
			    clearTimeout(this.showCode)
			    this.timmer = setInterval(() => {
			    	this.countDown = this.countDown - 1
                }, 1000)
			    this.showCode = setTimeout(()=>{
			    	this.qrCodeImage = this.codeList[this.num].code
			        this.showQRCode()
			    	this.showCodeImage()  
			    },this.qrCodeDuration)
			}
		},

		exitHome(){
			ipc.invoke(ipcApiRoute.trtcExitRoom, {}).then(r => {})
			closeWebsocket();
			this.updateLineState(0);
		},
		otherHelp(){
			ipc.invoke(ipcApiRoute.startThirdApp, 'ToDesk_Lite.exe').then(r => {})
		},
		minWindow(){
			ipc.invoke(ipcApiRoute.actionMainWindow, 'mini').then(r => {})
		},
		closeWindow(){
			this.$confirm('提示',{
				title: '提示',
				message: '此操作将会关闭应用程序，是否确认关闭？',
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(res => {
				this.exitHome();
				ipc.invoke(ipcApiRoute.actionMainWindow, 'close').then(r => {})
			}).catch(err => {})
		},
		quit(){
			this.$router.replace("/")
			this.exitHome();
			ipc.invoke(ipcApiRoute.closeWindowAll).then(r => {})
			ipc.invoke(ipcApiRoute.actionMainWindow).then(r => {})
		},
		itemHandel(item) {
			let options = {
				type: 'vue',
				content: item.route,
				windowName: item.name,
				winId: item.route.replace(/\//g,''),
				params: {
					winId: item.route.replace(/\//g,'')
				}
			}
			if(item.route === '/openReLabel'){
				options['width'] = 902
				options['height'] = 808
			}
			if(item.route === '/categoryInventory'){
				options['width'] = 1100
				options['height'] = 800
			}
			ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
		},
		
		initWebsocket() {
			const that = this;
			closeWebsocket()
			const obj = {}
			// 发起ws请求
			sendWebsocket(
				this.$http.wsRoot,
				obj,
				this.wsMessage,
				this.wsError,
				(e) => {
					that.$store.commit('changeWsConnected', true);
				},
				() => {
					that.$store.commit('changeWsConnected', false);
				}
			)
		},
		wsMessage(data) {
			let dataJson = JSON.parse(data);
			if (dataJson.indexOf('success') === -1 && !dataJson.includes('来自客户端的消息')) {
				dataJson = eval("(" + dataJson + ")")
			}
			if (dataJson) {
				// 透传指令处理
				this.updateLineState(1);
				if (dataJson['order']) {
					let order = eval('(' + dataJson.order + ')')
					console.log(`order  ${JSON.stringify(order)}`);
					let param = order.params
					if (order['tc'] === "trtc") {
						let options = {
							sdkAppId: Number(param['sdkAppId']),
							roomId: Number(param['roomId']),
							userSig: param['userSig'],
							userId: param['userId'],
							micVolume: param['microphoneVol'] ? Number(param['microphoneVol']) : 100,
							soundVolume: param['speakerVol'] ? Number(param['speakerVol']) : 100
						}
						ipc.invoke(ipcApiRoute.trtcEnterRoom, options).then(r => {})
					}
					if(order.microphoneVol || Number(order.microphoneVol) === 0){
						ipc.invoke(ipcApiRoute.trtcSetMicVolume, {
							volume: Number(order.microphoneVol)
						}).then(r => {})
					}
					if(order.speakerVol || Number(order.speakerVol) === 0){
						ipc.invoke(ipcApiRoute.trtcSetSoundVolume, {
							volume: Number(order.speakerVol)
						}).then(r => {})
					}
				} else {
					if (dataJson.title) {
						let messageAudio = new Audio(require("@/assets/audio/message.mp3"))
						messageAudio.play()
						dataJson.winId = 'message' + new Date().getTime()
						let options = {
							type: 'vue',
							width: 300,
							height: 400,
							content: '/message',
							windowName: '消息通知',
							winId: dataJson.winId,
							params: dataJson
						}
						ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
					}
				}
			}
		},
		wsError() {},
		//获取新闻列表
		fetchNewsListQuery() {
			NewsListQuery({
				queryType: '1'
			}).then(data => {
				this.messageTime = moment().format('HH:mm:ss');
				this.newsList = data.items || [];
				this.newsList.forEach(item => {
					if (item.thumbImages) {
						item.thumbImages.split(',')
					}
				})
			}).catch(err => {
				this.$message({type: "error", message: err, duration: 2000, showClose: true});
			})
		},
		showNewsDetail(item) {
			this.newsDetail = true;
			this.newsItem = item;
			this.srcLink = item['browseUrl'];
		},
		selectType(item) {
			this.typeItem = item;
			this.typeVisible = false;
		},
		fetchSalesDay() {
			salesQueryGrade({
				startTime: moment().format('yyyyMMDD'),
				endTime: moment().format('yyyyMMDD'),
				deptId: this.$cache.local.get('deptId')
			}).then(data => {
				this.todaySales = data
				this.salesTime = moment().format('HH:mm:ss')
			}).catch(err => {
				this.$message({type: "error", message: err, duration: 2000, showClose: true});
			})
		},
		fetchSalesMonth() {
			salesQueryGrade({
				startTime: moment().startOf('months').format('YYYYMMDD'),
				endTime: moment().endOf('months').format('YYYYMMDD'),
				deptId: this.$cache.local.get('deptId')
			}).then(data => {
				this.monthSales = data
				this.salesTime = moment().format('HH:mm:ss')
			}).catch(err => {
				this.$message({type: "error", message: err, duration: 2000, showClose: true});
			})
		},
		fetchDeptUser() {
			queryDeptUserSales({
				startTime: moment().format('yyyyMMDD'),
				endTime: moment().format('yyyyMMDD'),
				deptId: this.$cache.local.get('deptId')
			}).then(data => {
				this.guideList = data || []
				this.guideTime = moment().format('HH:mm:ss')
			}).catch(err => {
				this.$message({type: "error", message: err, duration: 2000, showClose: true});
			})
		},
		async updateLineState(state) {
			await updateTrtcState({state: state, deptId: this.$cache.local.get('deptId')})
		},
		checkUpdate() {
			let visCode = Number((this.curVersion).replaceAll(".", ""));
			appUpdate({
				current: visCode,
				platform: "client",
				userId: this.$cache.local.get('userId')
			}).then(data => {
				console.log(data)
				if (Number(data["versionCode"]) > visCode) {
					data.curVersion = this.curVersion;
					this.versionData = data;
					this.isUpdate = true;
				}
			}).catch(err => {
				this.$message({showClose: true, message: err, type: "error", duration: 2000});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	height: calc(100vh - 10px);
	overflow: hidden;
	.fixed-nav {
		height: 60px;
		border-radius: 8px 8px 0 0;
		box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.08);
		background: #fff;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99;
		.nav-left {
			margin-left: 20px;
			.nav-left-logo {
				font-size: 0;
				img {
					height: 40px;
				}
			}
		}
		.nav-tab {
			font-size: 18px;
			li {
				height: 60px;
				box-sizing: border-box;
				line-height: 60px;
			}
			position: absolute;
			left: 47%;
			top: 0;
		}
		.nav-right {
			position: relative;
			.name-avatar{
				.avatar {
					width: 34px;
					height: 34px;
					border-radius: 50%;
					color: #fff;
					margin-right: 10px;
					.user-img {
						width: 34px;
						height: 34px;
						border-radius: 50%;
					}
					.user-null {
						background: #067cf2;
						width: 34px;
						height: 34px;
						border-radius: 50%;
					}
				}
				.name {
					color: #333;
					font-size: 14px;
					line-height: 16px;
				}
				.right-tools {
					position: absolute;
					left: -20px;
					top: 42px;
					&:after {
						position: absolute;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid #fff;
						content: "";
						display: block;
						width: 0;
						height: 0;
						top: -8px;
						left: 30px;
					}
					.tools-list {
						box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.2);
						background: #fff;
						padding: 10px;
						width: 100px;
						text-align: center;
					}
				}
			}
			>i{
				font-size: 22px;
				cursor: pointer;
				margin-left: 20px;
			}
			.tools-line {
				width: 1px;
				height: 18px;
				background: #333;
				margin: 0 20px;
			}
			.nav-right-btn {
				margin-right: 10px;
				background: #EFF7FF;
				height: 36px;
				line-height: 36px;
				padding: 0 10px;
				border-radius: 8px;
				p>i{
					float: left;
					margin-top: 2px;
					margin-right: 2px;
				}
			}
			.icon-color {
				font-size: 21px;
			}
		}
	}
	
	.main-cont {
		-webkit-app-region: no-drag;
		margin: 70px 10px 10px;
		
		.main-left {
			width: 500px;
			height: calc(100vh - 80px);
			background: #fff;
			border-radius: 4px;
			margin-right: 10px;
			.aside-top {
				border-bottom: 1px solid #F5F5F5FF;
				height: 45px;
				padding: 0 10px;
				
				.top-title {
					font-size: 18px;
					font-family: 'font-Regular';
				}
				
				.top-more {
					color: #999999;
					font-size: 14px;
				}
			}
			
			.work-bench-cont {
				margin-top: 20px;
				
				.work-bench-item {
					width: calc(100% / 4);
					margin-bottom: 30px;
					box-sizing: border-box;
					color: #444;
					
					.bench-item-img {
						width: 60px;
						height: 60px;
						border-radius: 4px;
					}
				}
			}
		}
		
		.main-center {
			//width: calc(100% - 650px);
			flex: 1;
			height: calc(100vh - 10px);
			//margin: 0 10px;
			
			.main-wait {
				background: #fff;
				height: 425px;
				border-radius: 4px;
				.wait-title {
					height: 45px;
					padding: 0 10px;
					border-bottom: 1px solid #F5F5F5;
					.wait-title-left {
						font-family: font-Regular;
						> span {
							font-size: 18px;
						}
						.search {
							border: 1px solid #ccc;
							border-radius: 4px;
							background: #EEF2FB;
							margin-left: 50px;
							font-size: 14px;
							
							.search-type {
								height: 32px;
								position: relative;
								border-right: 1px solid #ccc;
								padding: 0 8px 0 10px;
								width: 100px;
								cursor: pointer;
								
								> div {
									width: 100%;
									
									i {
										font-weight: bold;
									}
								}
								
								.search-item {
									position: absolute;
									width: 126px;
									max-height: 200px;
									background: #EEF2FB;
									border-radius: 4px;
									top: 34px;
									left: 0;
									z-index: 99;
									div {
										height: 30px;
										border-bottom: 1px solid #ddd;
										line-height: 30px;
										padding: 0 10px;
										
										&:last-child {
											border-bottom: none;
										}
										
										&:hover {
											background: #e0eaf5;
										}
									}
								}
							}
							
							input {
								background: #EEF2FB;
								border: none;
								outline: none;
								width: 180px;
								padding: 0 8px;
							}
							
							.search-btn {
								background: #067CF2;
								color: #fff;
								border: none;
								font-size: 14px;
								padding: 0;
								width: 70px;
								height: 32px;
								border-radius: 0 4px 4px 0;
							}
						}
					}
					
					.wait-title-right {
						font-size: 14px;
						
						p {
							cursor: pointer;
						}
						
						p:first-child {
							color: #067CF2;
							margin-right: 10px;
						}
						
						p:last-child {
							color: #999;
						}
					}
				}
				
				.wait-table {
					margin: 10px;
					
					.table {
						width: 100%;
						box-sizing: border-box;
						
						.type {
							padding: 4px 8px;
							border-radius: 6px;
							color: #fff;
							background: #067CF2;
						}
						
						.invoice {
							color: #067CF2;
							border-bottom: 1px solid #067CF2;
							font-weight: bold;
							cursor: pointer;
						}
					}
				}
			}
			
			.main-msg {
				background: #fff;
				margin-top: 10px;
				height: calc(100vh - 510px);
				border-radius: 4px;
				
				.msg-title {
					height: 45px;
					padding: 0 10px;
					border-bottom: 1px solid #F5F5F5FF;
					
					.msg-title-left {
						font-size: 18px;
						font-family: 'font-Regular';
					}
					
					.msg-title-right {
						font-size: 14px;
						
						p {
							cursor: pointer;
						}
						
						p:first-child {
							color: #067CF2;
							margin-right: 10px;
						}
						
						p:last-child {
							color: #999;
						}
					}
				}
				
				.msg-list {
					height: calc(100vh - 568px);
					overflow: auto;
					
					.msg-card {
						margin: 10px;
						border-bottom: 1px solid #F5F5F5;
						padding-bottom: 10px;
						cursor: pointer;
						
						.msg-card-left {
							font-size: 0;
							
							.msg-img {
								width: 120px;
								height: 90px;
								border-radius: 4px;
								margin-right: 10px;
							}
						}
						
						.msg-card-right {
							height: 90px;
							width: calc(100% - 30px);
							
							.msg-cont {
								p {
									font-family: 'font-Regular';
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									font-size: 18px;
								}
								
								span {
									font-size: 12px;
									padding: 2px 10px;
									background: #FFEBD1;
									border-radius: 4px;
									color: #FF9000;
								}
							}
							
							.msg-info {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								font-size: 14px;
								margin: 10px 0;
								color: #666;
							}
						}
						
						.has-left {
							width: calc(100% - 160px);
						}
					}
				}
			}
		}
		
		.main-right {
			height: calc(100vh - 80px);
			width: 500px;
			
			.aside-top {
				padding: 10px;
				height: 45px;
				
				.top-title {
					font-size: 18px;
					font-family: 'font-Regular';
				}
			}
			
			.sales-card {
				box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
				background: #fff;
				height: 380px;
				border-radius: 4px;
			}
			
			.guider-card {
				box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
				background: #fff;
				border-radius: 4px;
				margin-top: 10px;
				height: calc(100vh - 460px);
				
				.guide-list {
					border-top: 1px solid #F5F5F5;
					font-size: 14px;
					
					.guide-title {
						background: #F9F9F9;
						color: #436481;
						margin: 10px 10px 0;
						height: 36px;
					}
					
					.guide-user {
						font-family: 'font-Regular';
						height: calc(100vh - 590px);
						overflow: auto;
						font-size: 18px;
						margin: 10px;
						
						> div {
							text-align: center;
							height: 60px;
						}
					}
				}
			}
			
			.card {
				padding: 10px;
				border-top: 1px solid #F5F5F5;
				position: relative;
				
				> p {
					font-family: 'font-Regular';
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sales-val {
					font-size: 30px;
					color: #F8475F;
					font-family: 'font-Regular';
					
					span {
						font-size: 14px;
						padding: 4px 15px;
						position: absolute;
						top: 15px;
						right: 15px;
						color: #fff;
						border-radius: 20px 20px 20px 2px;
					}
				}
				
				.sales-info {
					margin-top: 8px;
					padding-left: 15px;
					padding-right: 15px;
					
					span {
						font-size: 13px;
						color: #999;
					}
					
					p {
						font-size: 21px;
						font-family: 'font-Regular';
					}
				}
			}
		}
	}
	
	.top-refresh {
		font-size: 14px;
		color: #067CF2 !important;
		cursor: pointer;
		
		i {
			font-weight: bold;
			margin-right: 4px;
		}
	}
	
	.last-update {
		color: #999;
		margin-right: 10px;
		font-size: 14px;
	}
}
</style>