<template>
<section>
    <el-dialog
        title="提示"
        width="40%"
        top="30vh"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible">
        <section style="color: red;">
            <p style="font-size:24px;margin-top:-30px;">系统检测到未开启麦克风权限，请前往系统设置打开麦克风！</p>
            <div style="display:flex;justify-content: space-between;align-items:center;margin:50px 0 30px;font-size:18px;text-decoration: underline;cursor:pointer;">
                <span @click="dialogVisibleT = true;windowType = 'win10';">查看win10系统如何启动？</span>
                <span @click="dialogVisibleT = true;windowType = 'win11';">查看win11系统如何启动？</span>
            </div>
            <div style="color:#999;">不知道如何查看？请联系IT专员</div>
            <div style="display:flex;justify-content: end;margin-top:30px;">
                <div @click="reStart" style="background:#409eff;color:#fff;padding:10px 0;border-radius:4px;cursor:pointer;width:150px;text-align:center;">我已设置，刷新系统</div>
            </div>
        </section>
    </el-dialog>
    <el-dialog
        title="如何开启麦克风"
        top="5vh"
        width="40%"
        :visible.sync="dialogVisibleT">
        <section>
          <template v-if="windowType === 'win10'">
            <div v-for="item in win10List" :key="item">
              <el-image :src="item" :preview-src-list="win10List"></el-image>
            </div>
          </template>
          <div v-else v-for="item in win11List" :key="item">
            <el-image :src="item" :preview-src-list="win11List"></el-image>
          </div>
        </section>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisibleT = false">确 定</el-button>
        </span>
    </el-dialog>
</section>
</template>

<script>
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";

export default {
    data(){
        return{
            dialogVisible: false,
            dialogVisibleT: false,
            windowType: 'win10',
            win10List: [
              require('../assets/images/study/1.jpg'),
              require('../assets/images/study/2.jpg'),
              require('../assets/images/study/3.jpg')
            ],
            win11List: [
              require('../assets/images/study/4.jpg'),
              require('../assets/images/study/5.jpg'),
              require('../assets/images/study/6.jpg'),
              require('../assets/images/study/7.jpg')
            ]
        }
    },
    created(){
        this.dialogVisible = true;
    },
    methods: {
        reStart(){
			ipc.invoke(ipcApiRoute.restartApp, {}).then(r => {})
        }
    }
}
</script>

<style scoped>
</style>
