import Cache from '@/libs/common/cache.js'
let websock = null
let messageCallback = null
let errorCallback = null
let openCallback = null;
let closeCallback = null;
let wsUrl = ''
let tryTime = 0
let timer = null
let isJoinOn = false

// 接收ws后端返回的数据
function websocketonmessage (e) {
  // console.log(e)
  messageCallback(JSON.stringify(e.data))
  // messageCallback(e.data)
}

/**
 * 发起websocket连接
 * @param {Object} agentData 需要向后台传递的参数数据
 */
function websocketSend (agentData) {
    console.log(agentData)
  // 加延迟是为了尽量让ws连接状态变为OPEN
  setTimeout(() => {
    // 添加状态判断，当为OPEN时，发送消息
    if (websock && websock.readyState === websock.OPEN) { // websock.OPEN = 1
      // 发给后端的数据需要字符串化
      websock.send(JSON.stringify(agentData))
    }
    if (websock && websock.readyState === websock.CLOSED) { // websock.CLOSED = 3
      console.log('websock.readyState=3')
      // Message.error('ws连接异常，请稍候重试')
      errorCallback()
    }
  }, 1000)
}

// 关闭ws连接
function websocketclose (e) {
  console.log(e)
  isJoinOn = false
  // e.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
  // e.code !== 1000  表示非正常关闭。
  if (e && e.code !== 1000) {
    // Message.error('ws连接异常，请稍候重试')
    errorCallback()
    // 如果需要设置异常重连则可替换为下面的代码，自行进行测试
    clearTimeout(timer)
    timer = null
    // if (tryTime < 30) {
    //   timer = setTimeout(function() {
    //    websock = null
    //    tryTime++
    //    initWebSocket()
    //    console.log(`第${tryTime}次重连`)
    //  }, 5 * 1000)
    // } else {
    // //  Message.error('重连失败！请稍后重试')
    // }

    if(!isJoinOn){
      timer = setTimeout(function() {
        websock = null
        tryTime++
        initWebSocket()
        console.log(`第${tryTime}次重连`)
      }, 5 * 1000)
    }
  }
}
// 建立ws连接
function websocketOpen (e) {
  tryTime = 0;
  isJoinOn = true;
  if(openCallback) {
    openCallback(e);
  }
}

// 初始化weosocket
function initWebSocket () {
  if (typeof (WebSocket) === 'undefined') {
    console.log('您的浏览器不支持WebSocket，无法获取数据')
    return false
  }
  // ws请求完整地址
  const requstWsUrl = wsUrl + Cache.local.get('wsToken') + '?deptId=' + Cache.local.get('deptId') + '&unitId=' + Cache.local.get('marketingUnitId')
  console.log(requstWsUrl)
  websock = new WebSocket(requstWsUrl)

  websock.onmessage = function (e) {
    websocketonmessage(e)
  }
  websock.onopen = function () {
    websocketOpen()
  }
  websock.onerror = function () {
    // Message.error('ws连接异常，请稍候重试')
    if(closeCallback) {
      closeCallback();
    }
    errorCallback();
  }
  websock.onclose = function (e) {
    websocketclose(e)
  }
}

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} agentData 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 */
export function sendWebsocket (url, agentData, successCallback, errCallback, _openCallback, _closeCallback) {
  wsUrl = url
  initWebSocket()
  console.log(wsUrl)
  messageCallback = successCallback
  errorCallback = errCallback
  openCallback = _openCallback;
  closeCallback = _closeCallback;
  websocketSend(agentData)
}

/**
 * 关闭websocket函数
 */
export function closeWebsocket () {
  if (websock) {
    websock.close() // 关闭websocket
    websock.onclose() // 关闭websocket
  }
}
