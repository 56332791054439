<template>
    <section class="module-mask row align-center space-center">
        <div class="mask-content">
            <div class="mask-vision">当前基座版本：V{{versionData.curVersion}}</div>
            <div class="mask-circel">
                <img src="@/assets/images/update-icon.png" />
            </div>
            <div class="mask-log">
                <p>升级到最新基座版本：{{versionData.version}}</p>
                <div>发布时间：{{versionData.createTime}}</div>
                <section>{{versionData.updateLog}}</section>
            </div>
            <section v-if="updateNow">
                <el-progress :text-inside="true" :stroke-width="28" :percentage="percentage" style="width:600px;margin:30px auto 50px;"></el-progress>
            </section>
            <div class="mask-handel row align-center" v-else>
                <div class="update-now" @click="updateHandel">立即下载</div>
                <div class="update-after" @click="cancelUpdate" v-if="versionData.forceUpdate !== '1'">暂不下载</div>
            </div>
			<div class="open-folder row space-center align-center">
				<span v-if="percentage >= 100" @click="openFolder">打开文件夹</span>
			</div>
        </div>
    </section>
</template>

<script>
import {ipc} from "@/utils/ipcRenderer"
import {ipcApiRoute} from "@/api/main"
export default {
    name: 'UpdateModule',
    props: {
        versionData: {
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            percentage: 0,
            updateNow: false
        }
    },
	methods: {
        cancelUpdate(){
            this.$emit('close')
        },
		updateHandel(){
			ipc.invoke(ipcApiRoute.startDown, {
				url: this.versionData.downloadUrl,
				version: this.versionData.version
			}).then(r => {
				ipc.on('progress', (event, data)=>{
					this.updateNow = true
					this.percentage = Number(data.progress)
					if(this.percentage >= 100){
						this.$message({showClose: true, message: '新版本已下载完成，点击”打开文件夹“按钮安装新版本', type: "success", duration: 6000})
					}
				})
			})
		},
		openFolder(){
			ipc.invoke(ipcApiRoute.openFolder, this.versionData.version).then(r=>{
				ipc.invoke(ipcApiRoute.restartApp, 'exit').then(()=>{})
			})
		}
    }
}
</script>

<style lang="scss" scoped>
.module-mask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    height: 100vh;
    z-index: 9999;
    .mask-content{
        background:#fff;
        width:680px;
        border-radius:10px;
        position: relative;
        .mask-vision{
            font-size: 14px;
            font-family: 'font-Light';
            margin: 10px;
        }
        .mask-circel{
            position: absolute;
            width: 300px;
            top: -100px;
            left: 50%;
            margin-left: -150px;
            font-size: 0;
            img{
                width: 300px;
            }
        }
        .mask-log{
            margin: 100px 50px 0;
            >p{
                font-family: 'font-Regular';
                font-size: 30px;
                color: #333;
            }
            >div{
                font-size: 14px;
                margin: 10px 0 20px;
                color: #000;
            }
            section{
                line-height: 30px;
                font-size: 16px;
                color: #999;
                white-space:pre-line;
            }
        }
        .mask-handel{
            margin: 40px 0 20px 280px;
            text-align: center;
            font-size: 16px;
            .update-now{
                width: 120px;
                background: #067CF2;
                color: #fff;
                line-height: 40px;
                border-radius: 4px;
                cursor: pointer;
            }
            .update-after{
                border-bottom: 1px solid #ccc;
                color: #ccc;
                margin-left: 40px;
                margin-top: 15px;
                cursor: pointer;
            }
        }
		.open-folder{
			margin: 20px auto;
			height: 40px;
			span{
				display: inline-block;
				color: #fff;
				height: 40px;
				text-align: center;
				line-height: 40px;
				font-size: 16px;
				cursor: pointer;
				width: 200px;
				border-radius: 4px;
				background: #067CF2;
			}
		}
    }
}
</style>
