<template>
  <el-dialog
      :title="modalParams.title + '--详情'"
      width="60%"
      top="8vh"
      :visible.sync="dialogVisible"
      @close="close">
    <webview :src="src" style="width: 100%;height:700px;"></webview>
    <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="close">确 定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {submitBrowseRecord} from '@/libs/http/api.js';

export default {
  props: ['modalParams', 'src'],
  data() {
    return {
      dialogVisible: false,
      time: 0
    }
  },
  created() {
    this.dialogVisible = true;
    this.time = new Date().getTime();
  },
  methods: {
    close() {
      submitBrowseRecord({
        newsId: this.modalParams["id"],
        browseSec: Math.ceil((new Date().getTime() - this.time) / 1000) + ""
      })
      this.dialogVisible = false;
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
